.canvas-img {
  width: 130px;
  height: 110px;
}

.support-imgL {
  display: inline-block;
  margin: 0 auto;
}

.content-wrapper {
  max-width: 955px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 20px 50px 10px rgba(20, 61, 80, 0.15);
  background-color: #fff;
}

.ready-title {
  color: rgb(21, 99, 128);
  font-size: 2.5rem;
  font-weight: 800;
  padding: 15px;
}

@media screen and (max-width: 767px) {
  .ready-title {
    font-size: 1.5rem;
  }
}
