.blog-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-item {
  border-bottom: 2px solid black;
  margin: 0 1rem;
  width: fit-content;
}

.btn-apply {
  border-radius: 20px;
  background-color: red;
  border: none;
  color: white;
  padding: 10px 15px;
}

.blog-card {
  border-radius: "0px 0px 10px 10px";
}

.card-img {
  height: 25rem;
}

.card-img img {
  height: 22rem;
  border-radius: 10px 10px 0 0;
}

.article-content {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  bottom: 40px;
  background: #fff;
}

.view-field-body {
  visibility: hidden;
  opacity: 0;
  line-height: 0;
  margin: 0;
}

.blog-card:hover .article-content {
  bottom: 90px;
  transition-delay: 0s;
  transition-duration: 0.5s;
  overflow: hidden;
}

.blog-card:hover .view-field-body {
  visibility: visible;
  opacity: 1;
  line-height: 1.5;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 1200px) {
  .blog-list {
    flex-wrap: wrap;
  }

  .border-item {
    margin: 0 1rem;
    width: fit-content;
    padding-right: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .blog-card {
    border-radius: "0px 0px 10px 10px";
  }

  .article-content {
    bottom: 10px;
  }

  .view-field-body {
    visibility: hidden;
    opacity: 0;
    line-height: 0;
    padding: 0;
    margin: 0;
  }

  .blog-card:hover .article-content {
    bottom: 60px;
  }

  .blog-card:hover .view-field-body {
    visibility: visible;
    opacity: 1;
    line-height: 1.2;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .blog-list {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .border-item {
    border-bottom: 1px solid black;
    width: 100%;
  }

  .blog-card {
    border-radius: "0px 0px 10px 10px";
  }

  .card-img {
    height: 29rem;
  }
  .card-img img {
    height: 12rem;
  }

  .article-content {
    bottom: 0px;
  }

  .blog-card {
    pointer-events: none;
  }

  .view-field-body {
    pointer-events: none;
    visibility: visible;
    opacity: 1;
    line-height: 1.5;
    padding: 1rem 0px;
  }
}

@media screen and (max-width: 575px) {
  .cards-resp-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
}
