.title-date {
  color: #156380;
}

.layout-content {
  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
}

#why-attend-instrurecon {
  max-width: 560px;
  margin: 0 auto;
  margin-top: 30px;
  padding-top: 2rem;
}

.Acc-the-contButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.Schedule-title {
  text-align: center;
  font-size: 2.56rem;
  font-weight: 700;
}

.block-card-grid {
  color: #156380;
  position: relative;
}

.feildname-card {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.paragraph {
  height: 100%;
}

.paragraph-type-icon-card {
  text-align: center;
}

.feild-name--feild-icon {
  height: 56px;
  margin-bottom: 3rem;
}

.day-icon {
  margin-bottom: 5rem;
}

.feild-name--feild-heading {
  color: #156380;
  margin-bottom: 12px;
  font-size: 2.6rem;
  font-weight: 700;
}

.justAFew-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  padding-bottom: 1.5rem;
}

.feild-name--feild-description {
  color: #143d50;
  margin: 0 auto;
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

.number-design {
  font-size: 4rem;
  font-weight: 700;
  display: inline-block;
}

.feild-item {
  padding: 1.5rem;
}

.Jump-To .present-btn {
  border-radius: 20px;
  background-color: red;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: justify;
}

.feild-name--feild-description ul > li::before {
  content: ".";
  position: absolute;
  top: -4px;
  left: "-20px";
  font-size: 22px;
  color: rgba(21, 99, 128, 0.42);
}

.feild-name--feild-description ul {
  margin-bottom: 1rem;
  list-style-position: outside;
  list-style-type: disc;
}

.feild-name--feild-description ul li {
  margin-bottom: 1rem;
}

.justAFew-list ul li {
  margin-bottom: 0;
  line-height: 1.8;
}

.attendee-feedback {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #143d50;
  letter-spacing: 0.12em;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
}

@media screen and (max-width: 967px) {
  .feildname-card {
    flex-wrap: wrap;
  }
  .layout-content {
    max-width: 68.75rem;
    margin: 0 auto;
    padding: 0rem 3rem;
  }

  .Exp-best-Tsize {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  
  .feildname-card {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
  }

  .paragraph-type-icon-card {
    text-align: left;
    margin-top: -38px;
  }

  .feild-name--feild-icon {
    height: 56px;
    margin-bottom: 3rem;
    padding: 0;
  }

  .layout-content {
    max-width: 68.75rem;
    margin: 0;
    padding: 0rem;
  }

  .feild-item {
    padding: 0.7rem;
  }

  .feild-name--feild-heading p {
    font-size: 1.56rem;
  }

  .scheduleCard-date {
    text-align: center;
  }

  .day-icon {
    margin-bottom: 5rem;
    text-align: center;
    padding: 3rem auto;
  }

  .Jump-To .present-btn {
    width: 100%;
  }

  .justAFew-title {
    font-size: 1.8rem;
    font-weight: 700;
    padding-bottom: 3rem;
  }

  .Sched-Reg-btn {
    margin: 1.5rem;
  }

  #why-attend-instrurecon {
    max-width: 100%;
    margin: 0;
    margin-top: 0;
    padding-top: 0;
  }
  .Schedule-title {
    text-align: left;
    font-size: 1.56rem;
    font-weight: 700;
    padding-left: 1rem;
  }

  .justAFew-list ul {
    padding: 0.5rem;
  }

  .justAFew-list-together {
    padding: 0.25rem;
    margin-top: 3rem;
  }

  .Exp-best-Tsize {
    font-size: 1.5rem;
  }
}
