.main-container {
  padding: 50px;
}

.title-font-text {
  color: #287a9f;
  font-weight: 800;
  font-size: 3.5rem;
  margin-bottom: 0.5em;
}

#font-text-red {
  color: red;
}

.study-para-text {
  color: #143d50;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
}

.research-text {
  font-size: 1.2rem;
  font-weight: 800;
  color: #156380;
}

.research-img {
  border: 0;
  width: 100%;
  border-radius: 10px;
  height: auto;
  overflow: hidden;
}

.reserch-content {
  width: 50%;
  padding: 0 0 0 30px;
}

.research-font {
  font-size: 0.8rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-weight: 800;
  margin-bottom: 5px;
  padding: 0;
}

.card-text-featured {
  padding-top: 10rem;
}

#reducing-card {
  padding-top: 12rem;
}

@media screen and (max-width: 767px) {
  .main-container {
    padding: 25px;
  }

  .title-font-text {
    font-size: 2.5rem;
    font-weight: 600;
  }

  .study-para-text {
    font-size: 1rem;
    font-weight: normal;
    line-height: 25px;
  }

  .reserch-content {
    padding: 0 0 0 40px;
  }

  .research-text {
    font-size: 14px;
    margin: auto;
  }

  .research-font {
    font-size: 0.775rem;
  }

  .research-img {
    height: 100px;
  }

  #reducing-card {
    padding-top: 0rem;
  }

  .card-text-featured {
    padding-top: 0;
  }
}

.resource-type {
  line-height: 1.5;
  text-transform: none;
  padding: 0 25px;
  padding-top: 20px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  color: #4e6f7e;
}
