.paragraph-content {
  max-width: 68.75rem;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  z-index: 1;
}

.title-container {
  max-width: 585px;
  background: #fff;
  padding: 50px !important;
  border-radius: 10px;
  box-shadow: 0 20px 50px 10px rgba(20, 61, 80, 0.15);
}

.AI_btn-apply {
  padding: 13px 18px;
  border-radius: 25px;
  background-color: red;
  border: none;
  color: white;
}

.jump-to-arror-btn {
  border-radius: 20px 20px 20px 20px;
  background-color: red;
  border: none;
  color: white;
  padding: 10px 15px;
}

@media screen and (max-width: 767px) {
  .paragraph-content {
    max-width: 68.75rem;
    margin-right: auto;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: relative;
    z-index: 1;
  }
}
