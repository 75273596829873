.nav-items {
  box-shadow: none;
}

.nav-i {
  font-size: 14px;
  text-decoration: none;
  color: #156380;
  font-weight: 800;
}

.nav-i:hover {
  text-decoration: underline;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none;
}

.dropdown-menu {
  top: 100%;
  background-color: #156380;
}

.MainWidth {
  width: 25%;
  background-color: #f2f8fa;
}

@media screen and (min-width: 993px) {
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .nav-items:hover .dropdown-menu {
    display: block;
  }

  .nav-items {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .glob-box {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .glob-box {
    width: 100%;
    background-color: #f2f8fa;
  }

  .MainWidth {
    width: 100%;
  }
}

.k12HEADER {
  font-size: 22px;
  color: #1b6a89;
  font-weight: 800;
}

.k12HEADER:hover {
  text-decoration: underline;
  color: #1b6a89;
  background-color: white;
}

.k12HIGHEREDUCATIONHEADER {
  font-size: 18px;
  color: #1b6a89;
  font-weight: 800;
}

.k12HIGHEREDUCATIONHEADER:hover {
  text-decoration: underline;
  color: #1b6a89;
  background-color: white;
}

.HEADERK12 {
  color: #143d50;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
}

.HEADERK12:hover {
  text-decoration: underline;
  color: #143d50;
  background-color: white;
}

.HEADERK123 {
  color: #143d50;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
}

.HEADERK123:hover {
  text-decoration: underline;
  color: #143d50;
  background-color: white;
}

.HEADERKHIGHEREDUCATION {
  color: #143d50;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.HEADERKHIGHEREDUCATION:hover {
  text-decoration: underline;
  color: #143d50;
  background-color: white;
}

.SOLHEADER {
  color: #143d50;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2;
}

.SOLHEADER:hover {
  text-decoration: underline;
  color: #143d50;
  background-color: #e0eaf0;
}

.SOLHEADER1 {
  color: #143d50;
  font-size: 0.975rem;
  font-weight: 400;
  line-height: 1.2;
}

.SOLHEADER1:hover {
  text-decoration: underline;
  color: #143d50;
  background-color: #fff;
}

.center {
  font-size: 0.875rem;
  margin-top: 10px;
  color: #143d50;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.12em;
  display: block;
}

.center:hover {
  background-color: white;
  color: #143d50;
}

.HEADERTRYCANVASBUTTON {
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #e4060f;
  background-color: unset;
  border: none;
  line-height: 1.2;
}

.HEADERTRYCANVASBUTTON:hover {
  color: #f76400;
}

.HEADERTRYCANVASBUTTONHEADERKHIGHEREDUCATION {
  font-size: 0.775rem;
  letter-spacing: 1px;
  color: #e4060f;
  background-color: unset;
  border: none;
  line-height: 1.2;
}

.HEADERTRYCANVASBUTTONHEADERKHIGHEREDUCATION:hover {
  color: #f76400;
}
