.Values-text-box{
  max-width: 40%;
}

.O-mMile-text{
  color: rgb(21, 99, 128);
  letter-spacing: 1px;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.2;
}

.ourst-ready-txt{
  font-size: 2.6rem;
  font-weight: 800;
}


@media screen and (max-width: 767px) {
    .Values-text-box{
        margin-top: 15%;
        max-width: 100%;
    } 

    /*-Our Story-*/
    .OurStory-main {
      margin: 0 auto;
      padding: 1.5rem .9rem;
    }

    .O-mMile-text{
      font-size: 1.68rem;
      font-weight: 700;
      line-height: 1;
    }

    .ourst-ready-txt{
      font-size: 1.68rem;
      font-weight: 700;
    }

}