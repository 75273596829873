

body{
    background-color: #F2F8FA;
}


/* FONT FAMILY START */

.UbuntuFONTFAMILY {
    font-family: 'Ubuntu', sans-serif;
}


.fontFamilyHeader {
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
}

.fontFamilyHeader1 {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily {
    font-family: 'Kanit', sans-serif;
}

.SolutionDropDownFontFamily1 {
    font-family: 'Kanit', sans-serif;
    line-height: 40px;
}

.SolutionDropDownFontFamily2 {
    font-family: 'Kanit', sans-serif;
    line-height: 23px;
}

.SolutionDropDownFontFamily3 {
    font-family: 'Kanit', sans-serif;
    line-height: 35px;
}

.SolutionDropDown {
    font-family: 'Roboto', sans-serif;
}

.h1FontFamily {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
}

.h1FontFamily1 {
    font-family: 'Ubuntu', sans-serif;
}

.h1FontFamilyLineHeight {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 900;
    line-height: 50px;
}

/* FONT FAMILY END */


    /* Teronary Operator */


    .btnStyle {
        width: 100%;
        padding: 15px;
        color: #0072BA;
        border: none;
        background-color: #F2F8FA;
    }

    .btnStyle:hover {
        color: red;
        padding: 15px;
        border-bottom: 4px solid red;  
    }
    
    .newStyle {
        width: 100%;
        padding: 15px;
        color: #0072BA;
        border: none;
        background-color: #F2F8FA;    }

    .newStyle:hover {
        color: red;
        padding: 15px;
        border-bottom: 4px solid red;   
    }

    .btn3Style {
        width: 100%;
        padding: 15px;
        color: #0072BA;
        border: none;
        background-color: #F2F8FA;    }

    .btn3Style:hover {
        color: red;
        padding: 15px;
        border-bottom: 4px solid red;   
    }
    
    .btn4Style {
        width: 100%;
        padding: 15px;
        color: #0072BA;
        border: none;
        background-color: #F2F8FA;    }

    .btn4Style:hover {
        color: red;
        padding: 15px;
        border-bottom: 4px solid red; 
    }

    /* Ternary opertor end */


.imgRESOPNSIVE{
    width: 640px;
    border-radius: 10px 10px 10px 10px;
}

.elevate12{
    width: 550px;
    border-radius: 10px 10px 10px 10px;
}


.PLUS{
    height: 100%;
    margin-top: 150px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
}

.PLUS-SMALL{
    height: 100%;
    margin-top: 150px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
}

.PLUS-LARGE{
    height: 100%;
    margin-top: 100px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
}

.PLUS-MINUS-CATALOG{
    height: 100%;
    margin-top: 150px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
}

.PLUS-MINUS-ITEM-BANK{
    height: 100%;
    margin-top: 150px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
}


.HOMEBGIMG{
    height:100%;
}


.width40{
    width: 40%;
}



@media only screen and (max-width: 1200px) {
    .HOMEBGIMG{
        height:100%;
    }
    .imgRESOPNSIVE{
        width:100%;
    }
    .elevate12{
        width: 100%;
    }
    .PLUS{
        height: 100%;
        margin-top: 50px;   
    }
    .PLUS-SMALL{
        height: 100%;
        margin-top: 50px;   
    }
    .PLUS-LARGE{
        height: 100%;
        margin-top: 50px;   
    }
    .PLUS-MINUS-CATALOG{
        height: 100%;
        margin-top: 50px;   
    }
    .PLUS-MINUS-ITEM-BANK{
        height: 100%;
        margin-top: 50px;   
    }
    .width40{
        width: 100%;
    }
    
}



   