#Upcomming-heading-title {
  font-weight: 800;
  font-size: 3.5rem;
}

#upcomming-Event-date {
  font-weight: 600;
  font-size: 1.5rem;
}

.event-bgImg-container {
  position: relative;
  padding: 20px;
  height: 650px;
  background-image: url("../../img/UpcommingEventBg.jpg");
  background-attachment: local;
  background-repeat: no-repeat;
  background-position: center, center;
  background-size: cover;
}

.upcomming-data-text {
  display: block;
  width: 50%;
  position: absolute;
  left: 25%;
  top: 25%;
  background-color: transperent;
  text-align: center;
  color: #ffffff;
}

.card-img {
  width: 100%;
  cursor: pointer;
}

.img-blend img {
  z-index: 0;
  height: 12rem;
}

.eventDate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #287a9f;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
}

.date-span-visible {
  visibility: hidden;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
}

.c-data-container {
  position: relative;
  z-index: 0;
  background-color: #ffffff;
  border-radius: 0 0 15px 15px;
  height: 15rem;
}

.case-two-container {
  background-color: #ffffff;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.sub-c-data {
  padding: 0.8rem;
}

.view-field-show {
  display: none;
}

.card-img:hover .date-span-visible {
  visibility: visible;
  text-align: center;
}

.card-img:hover .view-field-show {
  display: block;
}

.card-img:hover .case-two-container {
  bottom: 0px;
  transition-delay: 1s;
  transition-duration: 0.1s;
  overflow: hidden;
  height: 24rem;
}

.view-field-body {
  visibility: hidden;
  opacity: 0;
  line-height: 1.5;
  color: rgb(20, 61, 80);
  font-size: 1rem;
}

.card-img:hover .view-field-body {
  visibility: visible;
  opacity: 1;
}

.lern-more-btn {
  position: absolute;
  top: 200px;
}

.card-img:hover .lern-more-btn {
  top: 350px;
  padding: 0px;
}

.row-third-mar {
  margin-top: auto;
}

.InstructureCon {
  font-size: 40px;
}

@media screen and (min-width: 1200px) {
  .row-third-mar {
    margin-top: 3.5rem;
  }
}

@media screen and (max-width: 992px) {
  .view-field-body {
    line-height: 1.4;
    font-size: 1rem;
  }

  .c-data-container {
    height: 17rem;
  }

  .card-img:hover .lern-more-btn {
    top: 380px;
    padding: 0px;
  }

  .card-img:hover .case-two-container {
    bottom: -12px;
    height: 26rem;
  }
  .noHover {
    pointer-events: none;
  }
}

@media screen and (max-width: 767px) {
  .noHover {
    pointer-events: none;
  }

  .event-bgImg-container {
    position: relative;
    object-fit: cover;
    height: 650px;
    padding: 20px;
    background-image: url("../../img/UpcommingEventBg.jpg");
    background-attachment: cover;
    background-repeat: no-repeat;
    background-position: center, center;
  }

  .upcomming-data-text {
    display: block;
    width: 90%;
    position: absolute;
    left: 5%;
    top: 25%;
    background-color: transperent;
    text-align: center;
    color: #ffffff;
  }

  #Upcomming-heading-title {
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 15px;
  }
}
